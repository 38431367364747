module.exports = fontFamily => ({
  ".text-h1": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "3.5rem",
    "line-height": "4rem",
    "letter-spacing": "-1px",
  },
  ".text-h2": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "3rem",
    "line-height": "3.5rem",
    "letter-spacing": "-1px",
  },
  ".text-h3": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "2.25rem",
    "line-height": "2.75rem",
  },
  ".text-h4": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "1.5rem",
    "line-height": "2rem",
  },
  ".text-h5": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "1.25rem",
    "line-height": "1.625rem",
  },
  ".text-h6": {
    "font-family": fontFamily.sans,
    "font-weight": 700,
    "font-size": "1rem",
    "line-height": "1.375rem",
  },
  ".text-h7": {
    "font-family": fontFamily.sans,
    "font-weight": 600,
    "font-size": "0.875rem",
    "line-height": "1.25rem",
  },
  ".text-subtitle": {
    "font-family": fontFamily.sans,
    "font-weight": 400,
    "font-size": "1rem",
    "line-height": "1.375rem",
  },
  ".text-subtitle-1": {
    "font-family": fontFamily.sans,
    "font-weight": 400,
    "font-size": "1.25rem",
    "line-height": "1.75rem",
  },
  ".text-button": {
    "font-family": fontFamily.sans,
    "font-weight": 600,
    "font-size": "0.875rem",
    "line-height": "1.25rem",
  },
  ".text-body1": {
    "font-family": fontFamily.sans,
    "font-weight": 400,
    "font-size": "1rem",
    "line-height": "1.5rem",
  },
  ".text-body2": {
    "font-family": fontFamily.sans,
    "font-weight": 400,
    "font-size": "0.875rem",
    "line-height": "1.25rem",
  },
  ".text-overline": {
    "font-family": fontFamily.sans,
    "font-weight": 400,
    "font-size": "0.625rem",
    "line-height": "0.875rem",
  },
})
